<template>

<div class="container">
    <div v-if="done" class="row culture my-md-5">
        <img loading="lazy" class="background_right_v" src="background_v.svg" alt="Logo Vivify" style="left: -30%">
        <div class="col-12 col-lg-7 bring-front">
            <div class="row">
                <div class="col-12 my-5">
                    <span class="title">{{data.slide_title}}</span>
                </div>
                <div class="col-12">
                    <p class="text mb-4">
                        {{data.text_1}}
                    </p>
                    <p class="text">
                        {{data.text_2}}
                    </p>
                </div>
            </div>
             
        </div>
    </div>
</div>  
                    
<div>
    <div v-if="done" class=" culture light-gray-background py-5 ">
    
        <div class="container challenge-form-container">
            
            <div class="row mt-3"> 
                <div class="d-none d-md-none d-lg-block col-12 col-lg-6 pe-lg-5 form-side-img"   > 
                    <img loading="lazy" :src="data.form_image" alt="Imagem Form">
                </div> 
    
                <div v-if="done" class="col-12 col-lg-6 form-challenge ps-lg-5"  > 
                    <div class="title">{{data.form_title}}<span>.</span></div>
                    
                    <div class="row mt-3">
                        <div class="col-12 col-md-6 py-1">
                            <input v-model="nome" type="text" :placeholder="data.name" :class="{error:errors.includes('nome')}" :disabled="loading">
                        </div>
                        <div class="col-12 col-md-6 py-1">
                            <input v-model="email" type="email" :placeholder="data.email" :class="{error:errors.includes('email')}" :disabled="loading">
                        </div>
                        <div class="col-12 py-1">
                            <input v-model="empresa" type="text" :placeholder="data.company" :class="{error:errors.includes('empresa')}" :disabled="loading">
                        </div>
                        <div class="col-12 py-1">
                            <textarea v-model="mensagem" :placeholder="data.message" :class="{error:errors.includes('mensagem')}" :disabled="loading"></textarea>
                        </div> 
                        <div class="col-12 py-1"> 
                        </div>

                        <div class="col-12 mt-2"> 
                            <div class="row py-1">
                                <div class="col-12 challenge-filters-title mb-2">{{data.services}}</div>
                                <div class="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 challenge-filters mb-2" v-for="s in services">
                                    <div class="filter">
                                        <img loading="lazy" @click="filter(s.id)" class="" :src="s.image_url" :alt="s.name">
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <div class="col-12 py-1">
                            <input v-model="date" type="text" :placeholder="data.date" :class="{error:errors.includes('date')}" :disabled="loading">
                        </div>
                            
                        <div class="col-12 mt-2">
                            <div class="row">
                                <div class="d-none d-sm-block col-sm-7 col-xl-8"></div>
                                <!-- style="padding: 4px;" -->
                                <div class="col-12 col-sm-5 col-xl-4" >
                                    <button @click="recaptcha" class="submit" :disabled="loading">
                                        <span v-if="!loading">{{data.submit}}</span>
                                        <span v-else><n-spin size="small"/></span>
                                    </button>
                                </div>  
                            </div>
                        </div> 

                    </div>
                </div>
            </div> 
        </div>
    </div>
</div> 


<testimonials></testimonials>
 


             
    
</template>
  
<script>
   
import testimonials from '../../components/testimonials/testimonials'
import { useReCaptcha } from "vue-recaptcha-v3";
import { useNotification } from 'naive-ui'; 
import { computed } from "vue"; 
import { useLanguageStore } from '../stores/language';
import fetchRetry from 'fetch-retry';
const fetch = fetchRetry(global.fetch);

export default {
    components:{testimonials},

    data(){
        return{
            data:[], 
            data_blogs:[], 
            done:null,
            tab_width: window.innerWidth < 720 ? 1 : window.innerWidth < 1024 ? 2 :4 ,
            // todo mudar para false
            loading:false,
            errors:[],
              
            date:null,
            services:[],
            services_selected:[],
            token:null,
        }
    },
    mounted(){
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
            this.recaptcha = async () => {
                await recaptchaLoaded()
                this.token = await executeRecaptcha('login')
                this.submitForm();
            }
    },

    async created(){
        // console.log(this.$parent.$parent.$parent);
        this.$parent.$parent.$parent.resetFlag();        
    
        await fetch('https://cms.vivify.pt/wp-json/options/challenge'+this.selectedLanguage.lang_url,{retries: 5,retryDelay: 800})
            .then(res => res.json()) 
            .then(res =>{
                this.data=res['challenge'][0];
                this.data_blogs=res['blogs']; 
                this.services=res['categories'];
                this.done=true;
 
                // console.log(this.data);
            })
            
        this.$parent.$parent.$parent.addtoPercentage(33);

    },
 
    setup(){
        const notification = useNotification()
        
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        }; 

        return {
            error(content,title) {
                notification.error({
                    title:title,
                    content: content
                })
            },
            success(content,title) {
                notification.success({
                    title:title,
                    content: content
                })
            },
            selectedLanguage, changeLanguage
        }
    },

    methods:{

        formatDate() {
        // Remover qualquer caractere que não seja número
        let numbersOnly = this.date.replace(/\D/g, "");

        // Aplicar o formato DD/MM/AAAA
        if (numbersOnly.length > 2) {
            numbersOnly = numbersOnly.substring(0, 2) + "/" + numbersOnly.substring(2);
        }
        if (numbersOnly.length > 5) {
            numbersOnly = numbersOnly.substring(0, 5) + "/" + numbersOnly.substring(5, 9);
        }
 
            this.date = numbersOnly;
        },
        
        filter(f) {
            console.log(f)
            let target = event.target.parentElement;
            target.classList.toggle('active');

            if(target.classList.value.includes('active')){
                this.services_selected.push(f);
            }else{
                this.services_selected.splice(this.services_selected.indexOf(f),1);
            }       
        },
        getNames(){
            let names = []
            this.services_selected.forEach(element => {
                names.push(this.services.find(s => s.id == element).name);
            });
            return names.join(',');
        }, 
        async submitForm() {
            if(this.checkForm()){
                this.loading=true;
                let services_selected_names = this.getNames();

                $('#error_submit').hide();
 
                await fetch('https://cms.vivify.pt/wp-json/options/send-email-project',{
                    method:'POST',
                    headers: {
                        'Content-Type': 'application/json',  // Ensure the content type is JSON
                    },
                    body:JSON.stringify(
                        {
                            token:this.token,
                            subject:'PROPOSTA DE PROJETO',
                            message:'EMPRESA :'+this.empresa+"\nNOME: "+this.nome+"\nEMAIL: "+this.email+"\nMENSAGEM: "+this.mensagem+"\nSERVIÇOS: "+services_selected_names+"\nDATA: "+this.date
                        }
                    ) 
                })
                .then(res =>{
                    if(res.status==200){
                        this.success('Proposta enviada com sucesso!','ENVIADO COM SUCESSO');
                        // $('#success_submit').show();

                        // // Após 5 segundos (5000 milissegundos), aplicar o fadeOut
                        // setTimeout(function() {
                        //     $('#success_submit').fadeOut();
                        // }, 5000);

                        this.resetForm();
                        this.date = null;
                    }else{
                        this.error('Erro ao enviar Proposta','ENVIADO SEM SUCESSO')
                        // $('#error_submit').show();

                        // Após 5 segundos (5000 milissegundos), aplicar o fadeOut
                        // setTimeout(function() {
                        //     $('#error_submit').fadeOut();
                        // }, 5000);

                    }
                    this.loading=false; 
                })
            }
            // await this.$recaptchaLoaded()
            // const token = await this.$recaptcha('login')
        },
        resetForm(){
            this.empresa=null;
            this.nome=null;
            this.telefone=null;
            this.email=null;
            this.mensagem=null;
            this.contact=null;
            this.terms=false; 
        },
        checkForm(){
            // nome, email, empresa e fale-nos, são obrigatórios
            this.errors=[];
            if(!this.validEmail(this.email))this.errors.push('email'); 
            if(this.empresa=='' || this.empresa==null)this.errors.push('empresa');
            if(this.nome=='' || this.nome==null)this.errors.push('nome');

            if(this.mensagem=='' || this.mensagem==null)this.errors.push('mensagem'); 

            if(this.errors.length>=1)return false;
            return true;
        },
        validEmail:function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    }, 
}
</script>

<style>

</style>