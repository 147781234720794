<template>
    <div class="container">
        <div class="row mt-3">
            <!-- style="padding-left: 7% !important;padding-right: 7% !important;" -->
            <div class="d-none d-md-none d-lg-block col-12 col-lg-6 pe-lg-5 form-side-img"   >
                <!-- style="width:100%;height: 100%;border-radius: 40px;" -->
                <img loading="lazy" src="../../assets/img.png" alt="Imagem Form">
            </div>
            <!-- style="padding-left: 7% !important;padding-right: 7% !important;" -->
 
            <div v-if="done" class="col-12 col-lg-6 form-contact  ps-lg-5"  > 
                <div class="title">{{data.form_title}}<span>.</span></div>
                
                <div class="row mt-3">
                    <div class="col-12 py-1">
                        <input v-model="empresa" type="text" :placeholder="data.input_text_1" :class="{error:errors.includes('empresa')}" :disabled="loading">
                    </div>
                    <div class="col-12 py-1">
                        <input v-model="nome" type="text" :placeholder="data.input_text_2" :class="{error:errors.includes('nome')}" :disabled="loading">
                    </div>
                    <div class="col-12 py-1">
                        <input v-model="telefone" type="text" :placeholder="data.input_text_3" :class="{error:errors.includes('telefone')}" :disabled="loading">
                    </div>
                    <div class="col-12 py-1">
                        <input v-model="email" type="email" :placeholder="data.input_text_4" :class="{error:errors.includes('email')}" :disabled="loading">
                    </div>
                    <div class="col-12 py-1">
                        <textarea v-model="mensagem" :placeholder="data.input_text_5" :disabled="loading"></textarea>
                    </div>
                    <div class="col-12 py-1" style="padding-left: 1rem !important;padding-right: 1rem !important;">
                        <span class="sub-title" :class="{errorc:errors.includes('contact')}">{{data.checkboxes_title}}</span>
                    </div>
                    <div class="col-12 row py-1 checkbox-group" style="padding-left: 1rem !important;padding-right: 1rem !important;">
                        <div class="col-12 col-sm-4 col-lg-3 col-xl-4">
                            <input v-model="i0" @click="changeCheck(0)" type="checkbox" name="" id="" :disabled="loading">
                            <span>{{data.checkbox_1}}</span>
                        </div>
                        <div class="col-12 col-sm-4 col-lg-4 col-xl-4">
                            <input v-model="i1" @click="changeCheck(1)" type="checkbox" name="" id="" :disabled="loading">
                            <span>{{data.checkbox_2}}</span>
                        </div>
                        <div class="col-12 col-sm-4 col-lg- 5 col-xl-4">
                            <input v-model="i2" @click="changeCheck(2)" type="checkbox" name="" id="" :disabled="loading">
                            <span>{{data.checkbox_3}}</span>
                        </div>
                    </div>
                    <div class="col-12 py-1" style="margin-top: 10px;">
                        <input v-model="terms" type="checkbox" name="" id="" :class="{error:errors.includes('terms')}" :disabled="loading">
                        <span class="terms" :class="{errorc:errors.includes('terms')}">{{data.terms_and_conditions}}</span>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="d-none d-sm-block col-sm-7 col-xl-8"></div>
                            <div class="col-12 col-sm-5 col-xl-4">
                                <button @click="recaptcha" class="submit" :disabled="loading">
                                    <span v-if="!loading">{{data.submit}}</span>
                                    <span v-else><n-spin size="small"/></span>
                                </button>
                            </div>
                        </div>
                    </div>

                    
                </div>

                <div class="row">
                    <div class="col-12 contact-left">
                        <div class="title-address">{{data.title_address}}</div>
                        <div class="w-100">
                            <a href="mailto:hi@viivfy.pt">{{data.email}}</a>
                        </div>
                        <div class="w-100">
                            <span class="addr">{{data.address}}</span>
                            <span class="addr">{{data.postal}}</span>
                        </div>
                    </div>
                </div>

                
            </div>

                
        </div>
    </div> 
    <challenge_vue v-if="done" :title="data.challenge[0].title" :text="data.challenge[0].text" :button="data.challenge[0].button"></challenge_vue>
              
</template>
  
<script>
import { useNotification } from 'naive-ui'
import challenge_vue from '../../components/common/challenge'
import { useReCaptcha } from "vue-recaptcha-v3";
import { useLanguageStore } from '../stores/language';
import { computed } from "vue";
import fetchRetry from 'fetch-retry';
const fetch = fetchRetry(global.fetch);

export default {
    components:{challenge_vue},
    data(){
        return{
            empresa:null,
            nome:null,
            telefone:null,
            email:null,
            mensagem:null,
            contact:null,
            terms:false,
            i0:false,
            i1:false,
            i2:false,
            errors:[],
            loading:false,
            data:[],
            done:null,
            recaptcha:null,
            token: null
        }
    },

    setup(){
        
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };

        const notification = useNotification()
        return {
            error(content,title) {
                notification.error({
                    title:title,
                    content: content
                })
            },
            success(content,title) {
                notification.success({
                    title:title,
                    content: content
                })
            },

            selectedLanguage, changeLanguage
        }
    },

     
    async created(){
        this.$parent.$parent.$parent.resetFlag();
        await fetch('https://cms.vivify.pt/wp-json/options/contacts'+this.selectedLanguage.lang_url,{retries: 5,retryDelay: 800})
            .then(res => res.json()) 
            .then(res =>{
                this.data=res['contacts'][0];
                this.done=true;
        })
        this.$parent.$parent.$parent.addtoPercentage(33);
        
    },

    mounted(){
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
            this.recaptcha = async () => {
                await recaptchaLoaded()
                this.token = await executeRecaptcha('login')
                this.submitForm();
            }

    },
    methods: {
        changeCheck(value){
            if(value==this.contact){this.contact=null;return;}
            switch(value){
                case 0:
                    this.i1=false;
                    this.i2=false;
                    break;
                case 1:
                    this.i2=false;
                    this.i0=false;
                    break;
                case 2:
                    this.i0=false;
                    this.i1=false;
                    break;
            }
            this.contact=value;
        },
        async submitForm() {
            if(this.checkForm()){
                let horario;
                this.loading=true;
                switch(this.contact){
                    case 0:
                        horario='09-13H';
                        break;
                    case 1:
                        horario='13H30-15H';
                        break;
                    case 2:
                        horario='15H30-18H30';
                        break;
                }
                await fetch('https://cms.vivify.pt/wp-json/options/send-email',{
                    method:'POST',
                    headers: {
                        'Content-Type': 'application/json',  // Ensure the content type is JSON
                    },
                    body:JSON.stringify(
                        {
                            token:this.token,
                            subject:'PEDIDO DE CONTACTO',
                            message:'EMPRESA :'+this.empresa+"\nNOME: "+this.nome+"\nTELEFONE: "+this.telefone+"\nEMAIL: "+this.email+"\nMENSAGEM: "+this.mensagem+"\nHORARIO: "+horario
                        }
                    ) 
                })
                .then(res =>{
                    if(res.status==200){
                        this.success('Pedido enviado com sucesso!','ENVIADO COM SUCESSO');
                        this.resetForm();
                    }else{
                        this.error('Erro ao enviar pedido','ENVIADO SEM SUCESSO')
                    }
                    this.loading=false; 
                })
            }

        },
        resetForm(){
            this.empresa=null;
            this.nome=null;
            this.telefone=null;
            this.email=null;
            this.mensagem=null;
            this.contact=null;
            this.terms=false;
            this.i0=false;
            this.i1=false;
            this.i2=false;
        },
        checkForm(){
            this.errors=[];
            if(!this.validEmail(this.email))this.errors.push('email'); 
            if(this.empresa=='' || this.empresa==null)this.errors.push('empresa');
            if(this.nome=='' || this.nome==null)this.errors.push('nome');

            if(this.telefone=='' || this.telefone==null)this.errors.push('telefone');
            if(this.terms==false)this.errors.push('terms');
            if(this.contact==null )this.errors.push('contact');

            if(this.errors.length>=1)return false;
            return true;
        },
        validEmail:function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    },
}
</script>

<style>

</style>