<template>
  <n-notification-provider>
    <div class="panel-loading" v-if="checkflag"></div>
    <div class="loading-box" v-if="checkflag ">
      <img loading="lazy" src="./assets/gif.gif" alt="Loading GIF" class="gif"/>
      <n-progress class="loading" :show-indicator="true" type="line" :percentage="percentage" processing />
    </div>
    <div :class="{blur:checkflag}" v-if="refreshFlag"  >
      <header_vue ref="header"></header_vue>
      <div class="main_body">
        <router-view></router-view>
      </div>
      <footer_vue></footer_vue>
    </div>
  </n-notification-provider>
</template>

<script>
  import header_vue from './components/common/header'
  import footer_vue from './components/common/footer'
  import {ref,computed} from 'vue'
  import { useLanguageStore } from './components/stores/language';

  export default {
    components:{header_vue, footer_vue},
    data(){
      return{
        percentage:0,
        checkflag:ref(true),
      }
    },
    setup(){
      const languageStore = useLanguageStore();
      const refreshFlag = computed(() => languageStore.refreshFlag);

      return { refreshFlag };
    },
    mounted(){
      // let recaptchaScript = document.createElement('script')
      // recaptchaScript.setAttribute('src', 'https://cdn.botpress.cloud/webchat/v2.2/inject.js')
      // document.head.appendChild(recaptchaScript)
      // recaptchaScript = document.createElement('script')
      // recaptchaScript.setAttribute('src', 'https://files.bpcontent.cloud/2024/11/15/11/20241115114911-CG845HSK.js')
      // document.head.appendChild(recaptchaScript)
    },
    watch:{
      refreshFlag:{
          handler(n,o){
            if(n == false){
              this.percentage=0;
              this.checkflag=true;
            }
          },
          deep: true
      }, 
    },

    methods:{ 
      addtoPercentage(value){
        if(this.percentage+value>=99){
          this.percentage=100;
          setTimeout(() =>this.checkflag=false , 1000)
        }else{
          this.percentage+=value;
        }
      },

      resetFlag(){
        this.checkflag=true;
        if(this.percentage==100)this.percentage=66;
      },

      afterNavigate() {
        const mobileMenu = document.getElementById('all-mobile');
          
          if (mobileMenu) {  
            const collapseHeader = mobileMenu.querySelector('.n-collapse-item__header-main');
      
            const collapseHeaderActive = mobileMenu.querySelector('.n-collapse-item__header--active');
            
            if (collapseHeaderActive) { 
              this.closeMobileMenu(); 
            }
          }
      },    
      
    },

   
  }
</script>

<style>
.panel-loading
{
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    overflow: none;
    background-color: #242426;
    z-index: 996;
    opacity: 1;
}
.loading-box
{
    width: 250px;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 997;
    .gif{
        width: 60%;
        max-width: 120px;
    }

    .loading
    {
        position: relative;
        z-index:100;
        width: 100% !important;
        margin: 0 auto;
        margin-top: 2em;

        .n-progress-icon
        {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 1em;
            color: white;
        }

        .n-progress-icon.n-progress-icon--as-text
        {
            color: rgba(235, 235, 235, 1);
            font-family: 'roboto-light';
            font-size: 20px;
        }

        .n-progress-content
        {
            .n-progress-graph
            {
                .n-progress-graph-line
                {
                    --n-fill-color: #00ffaa !important;
                }
            }
        }
    }


}
</style>