import { defineStore } from 'pinia';

export const useLanguageStore = defineStore('language', {
  state: () => ({
    globalLang: JSON.parse(localStorage.getItem('globalLang')) || { lang: 'pt', lang_url: '' },
    refreshFlag: true,
  }),
  actions: {
    setLanguage(lang) {
      // lang = 'pt';
      // Gera o URL com base no idioma selecionado
      const made_url = lang === 'pt' ? '' : `?lang=${lang}`;

      // Atualiza o estado e o localStorage
      const updatedLang = { lang, lang_url: made_url };
      localStorage.setItem('globalLang', JSON.stringify(updatedLang));
      this.globalLang = updatedLang;

      this.refreshFlag = false;
      setTimeout(() => (this.refreshFlag = true), 500);
    },
  },
});
