<template>
    <div>
        <div style="width:100%;height:100%;position:relative; background-size: cover;background-repeat: no-repeat;" :style="{ 'background-image': 'url(' + banner.image_1 + ')' }">
            <div class="blue-filter"></div>
            <div class="container">
                <div class="row services py-5">
                    <div class="col-12 col-lg-11 bring-front">
                        <h1 class="gray-text text-uppercase">{{ banner.titulo }}</h1>
                    </div>
                    <div class="col-8 d-none d-sm-block">
                    </div>
                    <div class="col-12 col-sm-6 text bring-front" v-html="formatText(banner.descricao)">
                    </div>
                    <div class="col-6 d-none d-sm-block">
                    </div>
                </div> 
            </div> 
        </div>
    </div>

    <!-- <div class="container-xxl"> -->
    <div class="container">
        <div class="row">
            <div class="col-12" v-for="(area, index) in areas" :key="index">
                <div class="industry-area">
                    <n-card>
                        <div class="title">
                            <h2>{{ area.titulo_principal }}</h2>
                        </div>
                        <n-tabs type="line" animated>
                            
                            <n-tab-pane :name="'tab-'+index" :tab="tab.titulo_separador" v-for="(tab, index) in area.adicionar_separador" :key="index">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h4 class="tab-description">{{tab.descricao_separador}}</h4>
                                            </div>
                                            <div class="col-md-12">
                                                <ul class="items-list">
                                                    <li class="items" v-for="(item, indexJ) in tab.itens_do_separador" :key="indexJ">
                                                        <h4>{{ item.titulo_item }}</h4>
                                                        <p>{{ item.descricao_item }}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="product-images">
                                            <img loading="lazy" src="../../assets/pattern.svg" alt="Vivify Padrão">
                                        </div>
                                    </div>
                                </div>
                            </n-tab-pane>

                        </n-tabs>
                    </n-card>
                </div>
            </div>
        </div>
    </div>
    


    <!-- <div class="container-fluid light-gray-background py-5"> -->
    <div class="container-fluid light-gray-background py-5" style="padding-left: 0; padding-right: 0;">

        <div v-if="done" class="container demo-form-container">
                    
            <div class="row my-3"> 
                <div class="d-none d-md-none d-lg-block col-12 col-lg-6 pe-lg-5 form-side-img"   >
                    <!-- style="width:100%;height: 100%;border-radius: 40px;" -->
                    <img loading="lazy" :src="demo_form.form_image" alt="Imagem Form">
                </div> 

                <!-- <div class="form-challenge-title d-sm-block d-none">{{demo_form.form_title}}<span>.</span></div> -->
                <!-- style="padding: 0;" -->
                <div v-if="done" class="col-12 col-lg-6 form-challenge ps-lg-5" > 
                    <div class="title d-sm-block">{{demo_form.form_title}}<span>.</span></div>
                    
                    <div class="row mt-3">
                        <div class="col-12 col-md-6 py-1">
                            <input v-model="nome" type="text" :placeholder="demo_form.name" :class="{error:errors.includes('nome')}" :disabled="loading">
                        </div>
                        <div class="col-12 col-md-6 py-1">
                            <input v-model="email" type="email" :placeholder="demo_form.email" :class="{error:errors.includes('email')}" :disabled="loading">
                        </div>
                        <div class="col-12 py-1">
                            <input v-model="empresa" type="text" :placeholder="demo_form.company" :class="{error:errors.includes('empresa')}" :disabled="loading">
                        </div>
                        <div class="col-12 py-1 mt-2"> 
                            <div class="row p-0">
                                <div  :class="{error:errors.includes('product_demo')}" class="col-12 demo-select-title mb-2" style="border: 0 !important;">{{demo_form.project_demo_title}}</div> 
                                
                                <div class="col-12 mb-2" >
                                    <select v-model="product_demo" :class="{error:errors.includes('product_demo')}">
                                        <option v-for="project_d in projects_demo" :value="project_d.name">{{ project_d.name }}</option>
                                    </select>
                                </div> 
                            </div> 
                        </div>
                        <div class="col-12 py-1">
                            <textarea v-model="mensagem" :placeholder="demo_form.message" :class="{error:errors.includes('mensagem')}" :disabled="loading"></textarea>
                        </div> 
    
                        <div class="col-12 mt-2">
                            <div class="row">
                                <div class="d-none d-sm-block col-sm-7 col-xl-8"></div>
                                <div class="col-12 col-sm-5 col-xl-4">
                                    <button @click="recaptcha" class="submit" :disabled="loading">
                                        <span v-if="!loading">{{demo_form.submit}}</span>
                                        <span v-else><n-spin size="small"/></span>
                                    </button>
                                </div>  
                            </div>
                        </div> 
                    </div>
                </div>
            </div> 
        </div> 

    </div>
    
    <challenge_vue v-if="done" :title="data.challenge.challenge.title" :text="data.challenge.challenge.text" :button="data.challenge.challenge.button"></challenge_vue>
 

</template>
  
<script>
  
import challenge_vue from '../../components/common/challenge';
import { useReCaptcha } from "vue-recaptcha-v3";
import { useNotification } from 'naive-ui';
import { useLanguageStore } from '../stores/language';
import { computed } from "vue";
import fetchRetry from 'fetch-retry';
const fetch = fetchRetry(global.fetch);

export default {
    components:{challenge_vue},
    data(){
        return{
            data:[],
            banner:[],
            areas:[],
            collapse:[],
            refresh:true,
            demo_form:[],
            loading:false, 
            services:[],
            services_selected:[],

            projects_demo:[],

            done:false,
            errors:[],

            products: [],

            product_demo:null,
        }
    },
 
    async created(){
        this.$parent.$parent.$parent.resetFlag();
        await fetch('https://cms.vivify.pt/wp-json/options/industry'+this.selectedLanguage.lang_url,{retries: 5,retryDelay: 800})
            .then(res => res.json()) 
            .then(res =>{
                // console.log('start');
                
                this.data=res;
 
                this.banner = res['challenge']['banner_principal'][0];
                this.areas.push(res['challenge']['shiftpro']);
                this.areas.push(res['challenge']['manutencao_cmms']);
                this.areas.push(res['challenge']['iot']);
                this.areas.push(res['challenge']['outras_solucoes']);

                
                this.services=res['categories']; 
                this.demo_form = res['challenge']['demo_form']; 
                this.projects_demo = this.demo_form['projects_demo']; 

                // console.log(this.areas);
                //this.collapse=Array(this.data.topics.length).fill().map((_, i) => ''+i+'')
                this.refresh=false;
                setTimeout(() =>this.refresh=true , 250)

                this.done=true; 
            })

        this.$parent.$parent.$parent.addtoPercentage(33);
    },

    
    mounted(){
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
            this.recaptcha = async () => {
                await recaptchaLoaded()
                this.token = await executeRecaptcha('login')
                this.submitForm();
            }
    },
 
    setup(){
        const notification = useNotification();
        
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        }; 

        return {
            error(content,title) {
                notification.error({
                    title:title,
                    content: content
                })
            },
            success(content,title) {
                notification.success({
                    title:title,
                    content: content
                })
            },
            selectedLanguage, changeLanguage
        }
    },

    methods:{
        formatText(txt){
            if(txt==undefined || txt=='')return '';
            return txt.replace(/\. /g, '.<br>');
        },

 
        filter(f) {
            // console.log(f)
            let target = event.target.parentElement;
            target.classList.toggle('active');

            if(target.classList.value.includes('active')){
                this.services_selected.push(f);
            }else{
                this.services_selected.splice(this.services_selected.indexOf(f),1);
            }       
        },
        getNames(){
            let names = []
            this.services_selected.forEach(element => {
                names.push(this.services.find(s => s.id == element).name);
            });
            return names.join(',');
        }, 
        async submitForm() {
            if(this.checkForm()){
                this.loading=true;
                let services_selected_names = this.getNames();

                $('#error_submit').hide();
 
                await fetch('https://cms.vivify.pt/wp-json/options/send-email-project',{
                    method:'POST',
                    headers: {
                        'Content-Type': 'application/json',  // Ensure the content type is JSON
                    },
                    body:JSON.stringify(
                        {
                            token:this.token,
                            subject:'PEDIDO DE DEMO',
                            message:'EMPRESA :'+this.empresa+"\nNOME: "+this.nome+"\nEMAIL: "+this.email+"\nMENSAGEM: "+this.mensagem+"\nSERVIÇOS: "+this.product_demo 
                        }
                    ) 
                })
                .then(res =>{
                    if(res.status==200){
                        this.success('Proposta enviada com sucesso!','ENVIADO COM SUCESSO'); 

                        this.resetForm();
                        this.date = null;
                    }else{
                        this.error('Erro ao enviar Proposta','ENVIADO SEM SUCESSO') 

                    }
                    this.loading=false; 
                })
            } 
        },
        resetForm(){
            this.empresa=null;
            this.nome=null;
            this.telefone=null;
            this.email=null;
            this.mensagem=null;
            this.contact=null;
            // this.terms=false; 
            this.product_demo = null;
        },
        checkForm(){
            // nome, email, empresa e fale-nos, são obrigatórios
            this.errors=[];
            if(!this.validEmail(this.email))this.errors.push('email'); 
            if(this.empresa=='' || this.empresa==null)this.errors.push('empresa');
            if(this.nome=='' || this.nome==null)this.errors.push('nome'); 
            if(this.mensagem=='' || this.mensagem==null)this.errors.push('mensagem'); 
            if (!this.product_demo) this.errors.push('product_demo'); 

            if(this.errors.length>=1)return false;
            return true;
        },
        validEmail:function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }



    }
}
</script>

<style>

</style>