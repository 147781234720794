<template>
    
    <div v-if="done" class="portfolio"> 
        <div class="container">
            <div class="row  py-5"> 
                <div class="col-12 col-sm-4 small-title bring-front">
                    {{opportunities.title_1}}
                </div>
                <div class="col-8 d-none d-sm-block">
                </div>
                <div class="col-12 col-sm-9 title bring-front" >
                    {{opportunities.title_2}}
                </div>
                <div class="col-7 d-none d-sm-block">
                </div>
            </div>
        </div>
    </div>

    
    <div v-if="done" class="container">

        <div class="row my-5"> 
            <div v-if="have_positions" class="opportunities-list"> 
                <n-collapse arrow-placement="left" accordion> 
                    
                    <n-collapse-item  v-for="(opportunitie, index) in positions" :title="opportunitie['title']" >
                        <div class="content" v-html="opportunitie['content']"></div>
                    </n-collapse-item>

                </n-collapse> 
            </div>
            <div v-else>
                <h3 class="blue-text">{{opportunities.no_opportunities}}</h3>
            </div>
        </div>

    </div>

    <div class="light-gray-background">
        <div v-if="done" class="container py-5">
   
            <div class="row"> 
                
                <div class="col-12 col-lg-6 mb-4">
                    <h4
                        :class="spontaneous === false ? 'toggle-option spontaneous-active blue-text' : 'toggle-option blue-text'"
                        @click="setSpontaneous(false)"
                        >
                        {{opportunities.choose_spontaneous_false}}
                    </h4>
                    <h4
                        :class="spontaneous === true ? 'toggle-option spontaneous-active blue-text' : 'toggle-option blue-text'"
                        @click="setSpontaneous(true)"
                        >
                        {{opportunities.choose_spontaneous_true}}
                    </h4>
                </div>
    
                <div class="col-12 col-lg-6 form-opportunity  ps-lg-5"  > 


    
                    <div class="title">{{opportunities.form_title}}<span>.</span></div>
                    
                    <div class="row my-3"> 
                        <div class="col-12 py-1">
                            <input v-model="nome" type="text" :placeholder="opportunities.name" :class="{error:errors.includes('nome')}" :disabled="loading">
                        </div> 
                        <div class="col-12 py-1">
                            <input v-model="email" type="email" :placeholder="opportunities.email" :class="{error:errors.includes('email')}" :disabled="loading">
                        </div>



                        
                        <div v-if="!spontaneous" class="col-12 py-1 mt-2"> 
                            <div class="row p-0">
                                <div  :class="{error:errors.includes('posicao')}" class="col-12 position-select-title mb-2" style="border: 0 !important;">{{opportunities.position}}</div> 
                                
                                <div class="col-12 mb-2 position-select-div" >
                                    <select v-model="posicao" :class="{error:errors.includes('posicao')}">
                                        <option v-for="position in positions" :value="position['title']">{{ position['title'] }}</option>
                                    </select>
                                </div> 
                            </div> 
                        </div> 
                        <div v-else class="col-12 py-1">
                            <input v-model="posicao" type="text" :placeholder="opportunities.position" :class="{error:errors.includes('posicao')}" :disabled="loading">
                        </div>

    


                        <div class="col-12 py-1">
                            <textarea v-model="mensagem" :placeholder="opportunities.description" :class="{error:errors.includes('mensagem')}" :disabled="loading"></textarea>
                        </div>




                        <!-- input file --> 
                        <div class="col-12 py-1 no-border-error">


                            <!-- <label for="curriculo" class="drop-container" id="dropcontainer">
                                <span class="drop-title">{{opportunities.drop_files}}</span>
                                {{opportunities.or}}
                                <input 
                                type="file" 
                                ref="curriculo" 
                                id="curriculo" 
                                :disabled="loading" 
                                accept=".pdf" 
                                @change="handleFileUpload">
                            </label> -->

                            <label for="curriculo" class="drop-container" id="dropcontainer">
                                <span class="drop-title">{{ opportunities.drop_files }}</span>
                                {{ opportunities.or }}
                                <input 
                                    type="file" 
                                    ref="curriculo" 
                                    id="curriculo" 
                                    :class="{error: errors.includes('curriculo')}" 
                                    :disabled="loading" 
                                    accept=".pdf" 
                                    @change="handleFileUpload">
                            </label>
                            <p v-if="errors.includes('curriculo')"  :class="{error:errors.includes('curriculo')}">{{ opportunities.file_size_limit }}</p>

                            
                        </div>



                            
                        <div class="col-12">
                            <div class="row">
                                <div class="d-none d-sm-block col-sm-7 col-xl-8"></div>
                                <div class="col-12 col-sm-5 col-xl-4">
                                    <button @click="recaptcha" class="submit" :disabled="loading">
                                        <span v-if="!loading">{{opportunities.submit}}</span>
                                        <span v-else><n-spin size="small"/></span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        
                    </div>
    
                    
                </div>

                    
            </div>
        </div>  
    </div>
              
</template>
  
<script>
import { useNotification } from 'naive-ui'
import challenge_vue from '../../components/common/challenge'
import { useReCaptcha } from "vue-recaptcha-v3";
import { useLanguageStore } from '../stores/language';
import { computed } from "vue";

export default {
    components:{challenge_vue},
    data(){
        return{ 
            nome:null,
            telefone:null,
            email:null,
            mensagem:null,  
            errors:[],
            loading:false,
            data:[],
            done:null,
            recaptcha:null,
            token: null,
            opportunities: [],
            positions: [],
            spontaneous: false,
            have_positions: false,
            posicao: null, 
        }
    },

    async created(){
 
        this.$parent.$parent.$parent.resetFlag();
        await fetch('https://cms.vivify.pt/wp-json/options/opportunities'+this.selectedLanguage.lang_url)
            .then(res => res.json()) 
            .then(res =>{
                this.opportunities=res['opportunities'][0];
 
                this.positions = res['positions_articles']; 

                if (this.positions.length > 0 ) {
                    this.have_positions = true;
                }

                if (this.positions.length < 1 && this.opportunities.active) {
                    this.spontaneous = true;
                }

                this.done=true;  

        }).then(res =>{
                

                 
                const dropContainer = document.getElementById("dropcontainer")
                const fileInput = document.getElementById("curriculo")

                dropContainer.addEventListener("dragover", (e) => { 
                    e.preventDefault()
                }, false)

                dropContainer.addEventListener("dragenter", () => {
                    dropContainer.classList.add("drag-active")
                })

                dropContainer.addEventListener("dragleave", () => {
                    dropContainer.classList.remove("drag-active")
                })

                
                dropContainer.addEventListener("drop", (e) => {
                    e.preventDefault();
                    dropContainer.classList.remove("drag-active");

                    const files = e.dataTransfer.files; 

                    if (files[0].type !== 'application/pdf') { 
                        this.error(this.opportunities.only_pdf_file_allowed, this.opportunities.error);  
                    }

                    if (files.length > 1) { 
                        this.error(this.opportunities.only_one_file_allowed, this.opportunities.error);  
                    }

                    if (files[0].type === 'application/pdf' && files.length === 1) { 
                        fileInput.files = files;
                    }
 
                });

                // dropContainer.addEventListener("click", () => {
                //     // fileInput.click();  
                // });


        })

        this.$parent.$parent.$parent.addtoPercentage(33);
        
    },

    setup(){
        
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };

        const notification = useNotification()
        return {
            error(content,title) {
                notification.error({
                    title:title,
                    content: content
                })
            },
            success(content,title) {
                notification.success({
                    title:title,
                    content: content
                })
            },

            selectedLanguage, changeLanguage
        }
    },

     

    mounted(){
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
            this.recaptcha = async () => {
                await recaptchaLoaded()
                this.token = await executeRecaptcha('login')
                this.submitForm();
            }

    },
    methods: {

        setSpontaneous(value) {
        this.spontaneous = value; 
        },
         

        async submitForm() {
    if (this.checkForm()) {
        this.loading = true;

        const formData = new FormData();
        const fileInput = document.getElementById("curriculo");

        // Adicionar o(s) ficheiro(s) ao FormData
        if (fileInput && fileInput.files.length > 0) {
            Array.from(fileInput.files).forEach(file => {
                formData.append('file', file); // Note que 'file' é o nome esperado pelo endpoint do WordPress
            });
        }

        // Adicionar os outros dados ao FormData
        formData.append('token', this.token);
        formData.append('subject', this.spontaneous ? 'NOVA CANDIDATURA ESPONTÂNEA' : 'NOVA CANDIDATURA A VAGA');
        formData.append('message', `NOME: ${this.nome}\nEMAIL: ${this.email}\nPOSIÇÃO: ${this.posicao}\nMENSAGEM: ${this.mensagem}`);

        try {
   
            const response = await fetch('https://cms.vivify.pt/wp-json/options/send-email-opportunity', {
                method: 'POST',
                body: formData, // Não defina o cabeçalho 'Content-Type' aqui
            });

            if (response.ok) {
                // this.success('Pedido enviado com sucesso!', 'ENVIADO COM SUCESSO');
                this.success(this.opportunities.curriculum_sent, this.opportunities.success);
                this.resetForm();
            } else {
                // console.error('Erro ao enviar', response);
                // this.error('Erro ao enviar pedido', 'ENVIADO SEM SUCESSO');
                this.error(this.opportunities.curriculum_not_sent, this.opportunities.error);
            }

        } catch (error) {
            // console.error('Erro no upload:', error);
            // this.error('Erro ao enviar pedido', 'ENVIADO SEM SUCESSO');
            this.error(this.opportunities.curriculum_not_sent, this.opportunities.error);
        } finally {
            this.loading = false;
        }
    }
}
,




        resetForm(){ 
            this.nome=null;
            // this.telefone=null;
            this.email=null;
            this.posicao=null;
            this.mensagem=null;  
 
            const fileInput = document.getElementById("curriculo");
            if (fileInput) {
                fileInput.value = ""; // Remove os ficheiros selecionados
            }
        },
        checkForm(){
            this.errors=[];
            if(!this.validEmail(this.email))this.errors.push('email');  
            if(this.nome=='' || this.nome==null)this.errors.push('nome');
            if(this.posicao=='' || this.posicao==null)this.errors.push('posicao');
            if(this.mensagem=='' || this.mensagem==null)this.errors.push('mensagem');

            // if(this.telefone=='' || this.telefone==null)this.errors.push('telefone'); 


            // Validação de ficheiro
            const fileInput = this.$refs.curriculo; 
            if (fileInput && fileInput.files.length > 0) {
                const file = fileInput.files[0]; 
                const isPdf = file.type === 'application/pdf';

                if (!isPdf || (file.size > 2 * 1024 * 1024)) {
                    this.errors.push('curriculo');
                }
 
            } else {
                this.errors.push('curriculo');
            }

            if(this.errors.length>=1)return false;
            return true;
        },
        validEmail:function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    },
}
</script>

<style>

</style>