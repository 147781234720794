<template>
	
        <div class="row challenge no-margin">
            <div class="box">
                <div class="container"> 
                    <div class="col-12">
                        <span class="title">{{title}}</span>
                    </div>
                    <div class="col-12 col-lx-8">
                        <span>{{text}}</span>
                    </div>
        

                    <div class="col-12 my-3">
                        <div class="blue-button">
                            <RouterLink to="/challenge"> 
                                <!-- <a href="#">{{button}}</a> -->
                                {{button}}
                            </RouterLink>
                        </div>
                    </div>
                </div>

            </div>
            <!--<div class="opacity"></div>-->
        </div>

</template>
  
<script> 
  	export default {
		
		props: ['title', 'text', 'button'],

		data(){ 
			
		}, 
	}
</script>

<style>

</style>